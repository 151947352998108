<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.title')"
              label-for='title'
            >
              <b-form-input
                id='title'
                :value='searchFields.title'
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('real_state_type') }}</label>
            <v-select
              v-model='searchFields.real_state_type_id'
              :dir='$store.state.appConfig.layout.direction'
              :options='real_state_type_ids'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='status_list'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :have-dropdown-actions='true'
      :can-change-status='false'
      :custom-change-status='selectedItemToChangeStatus'
      :permissionKey='"real-estate-requests"'
    >
      <template v-slot:dropdown_actions='{ item, generalActions }'>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_change_status && checkPermission("can_change_status"))'
          @click='showModal(item,item.status)'
        >
          <feather-icon
            icon='FilterIcon'
            class='mr-50'
          />
          <span>{{ $t('status') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_view)'
          variant='link'
          :to="{ name: 'details-real-estate-request', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon='EyeIcon'
            class='mr-50'
          />
          <span>{{ $t('details') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>

    <b-modal
      id='modal-status'
      ref='modal-status'
      cancel-variant='outline-secondary'
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('change_status')"
      hide-footer
      :cancel-disabled='false'
    >
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-form
          novalidate
          @submit.prevent='changeStatus'
        >
          <validation-provider
            v-slot='{ errors }'
            vid='status'
            :name="$t('status')"
            rules='required'
          >
            <b-form-group
              :label="$t('status')"
            >
              <v-select
                v-model='form.status'
                :dir='$store.state.appConfig.layout.direction'
                label='label'
                :options='status_list'
                :reduce='item => item.value'
                :placeholder="$t('select')"
              >
                <template #no-options>{{ $t('No options') }}</template>
              </v-select>
              <!--              <small class='text-danger'>{{ errors[0] }}</small>-->
            </b-form-group>
          </validation-provider>
          <div class='d-flex'>
            <LoadingButton />
            <b-button
              variant='outline-secondary'
              @click='hideModal'
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'
import { getPermissions } from '@core/utils/utils'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-requests',
      singularName: 'real-estate-request',
      permissionKey:'real-estate-requests',
      permissions: [],
      user: null,
      status_list: [],
      real_state_type_ids: [],
      created_at: null,
      form: {
        status: null,
      },
      selectedItemToChangeStatus: null,

      searchFields: {
        title: null,
        real_state_type_id: null,
        status: null,
        created_at: null,
        region_id: null,
        city_id: null,
        district_id: null,
      },
    }
  },
  watch: {

    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },

  },

  created() {
    this.permissions = getPermissions()
    this.user = JSON.parse(localStorage.getItem('userData'))

    this.axios.get(`${websiteMainUrl()}/model-list/real-estate-request-status-mobile`)
      .then(res => {
        this.status_list = res.data.data
      })

    this.getRealEstateTypes()
  },
  methods: {
    checkPermission(permission = 'can_create') {

      if (this.user['role_id'] === 1 || this.user['role_id'] === 2) {
        return true
      }

      // if (this.generalActions?.can_create != null) {
      //   return this.generalActions?.can_create
      // }

      if (this.permissionKey) {
        return (this.permissions[this.permissionKey] ?? [])[permission] ?? false
      }

      return false
    },

    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),

    getRealEstateTypes() {
      this.axios.get(`${websiteMainUrl()}/model-options/types`)
        .then(res => {
          this.real_state_type_ids = res.data.data
        })
    },

    showModal(item, status) {
      status === 'approved' ? this.form.status = null : this.form.status = status

      this.$refs['modal-status'].show()
      this.item_id = item.id
    },
    hideModal() {
      this.$refs['modal-status'].hide()
      setTimeout(() => {
        this.item_id = null
        this.form.status = null
      }, 500)
    },
    changeStatus() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.patch(`/real-estate-requests/${this.item_id}/change-status`, this.form)
              .then(response => {
                this.selectedItemToChangeStatus = response.data.data
                this.hideModal()
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                  this.form.status = null
                })
              })
          }
        })
    },
  },
}
</script>
